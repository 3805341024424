import React, { useEffect, useState } from 'react';
import './LogoSection.css';
import EliteZone from '../Assets/EliteZoneWhite.svg'
import { motion } from 'framer-motion';
import FlipClock from './FlipClock';
import Pomegranate from '../Assets/Pomegranate.svg';


function LogoSection() {

  const ContainerVarients = {
    begin: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: .5,
        type: 'tween',
        ease: 'easeInOut',
        staggerChildren: 1,
      }
    }
  }

  const ChildVarients = {
    begin: {
      opacity: 0,
      x: 100
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 10,
        type: 'spring',
        stiffness: 100,
      }
    }
  }

  const [IsImgLoaded, setIsImgLoaded] = useState(false)
  const [IsImgLoaded2, setIsImgLoaded2] = useState(false)

  const targetDate = new Date("2024-12-19T23:59:59").getTime(); // Target date: 19 December 2024 

  const [MobileMode, setMobileMode] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setMobileMode((window.innerWidth <= 1000))
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, [])

  return (
    <div className='LogoSection_Container'>

      <motion.div className='LogoSection_Style'
        variants={ContainerVarients}
        initial="begin"
        animate={(IsImgLoaded && IsImgLoaded2) ? "visible" : "begin"}
      >

        <motion.div className='Logo_Text_Section'
          variants={ChildVarients}
        >
          <div> همزمان با یلدای ۱۴۰۳ </div>
          <motion.img
            src={Pomegranate}
            alt='img'
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 3, duration: .5, type: 'spring', stiffness: 250 }}
            onLoad={() => setIsImgLoaded2(true)}
          />
        </motion.div>

        {MobileMode ?
          <>
            <motion.div
              variants={ChildVarients}
              style={{margin:'20px 0 24px 0'}}
            >
              <FlipClock targetDate={targetDate} color="#FF5722" />
            </motion.div>

            <motion.img
              variants={ChildVarients}
              src={EliteZone}
              alt='Logo Img'
              onLoad={() => setIsImgLoaded(true)}
            />

          </>
          :
          <>
            <motion.img
              variants={ChildVarients}
              src={EliteZone}
              alt='Logo Img'
              onLoad={() => setIsImgLoaded(true)}
            />

            <motion.div
              variants={ChildVarients}
            >
              <FlipClock targetDate={targetDate} color="#FF5722" />
            </motion.div>
          </>
        }

      </motion.div>


    </div >
  )
}

export default LogoSection