import React from 'react';
import './Counter.css';
import LogoSection from '../Components/LogoSection';
import TextSection from '../Components/TextSection';
import ImgSection from '../Components/ImgSection';
import CTASection from '../Components/CTASection';
import { motion } from 'framer-motion'
import BadgesSection from '../Components/BadgesSection';
import CTATextSection from '../Components/CTATextSection';

function Counter() {
  return (
    <>
      <LogoSection />

      <div className='A_style'
      //  style={{ backgroundColor: '#333333', height: '100vh' }}
       >

        <div className='Counter_Container' style={{ backgroundColor: 'white' }}>
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 3 }}
          >
            <ImgSection />
          </motion.span>

          <motion.span
            initial={{ x: 50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 1, type: 'spring', stiffness: 100, delay: 3.5 }}
          >
            <TextSection />
          </motion.span>

          {/* <motion.span
            initial={{ scale: .7, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: .5, delay: 3.8, ease:'easeInOut' }}
          >
            <BadgesSection />
          </motion.span> */}

          {/* <motion.span
            initial={{ x: 50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 1, type: 'spring', stiffness: 100, delay: 3.5 }}
          >
            <CTATextSection />
          </motion.span> */}
        </div>

        {/* <motion.span
          initial={{ scale: 0.7, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 1, delay: 4.5 }}
          style={{ width: '100%', cursor: 'pointer' }}
          onClick={() => window.open("https://www.saze90.com/get-your-badge/", "_blank")}
        >
          <CTASection />
        </motion.span> */}

      </div>

    </>
  )
}

export default Counter