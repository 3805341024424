import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./FlipClock.css";

const FlipClock = ({ targetDate, fontSize = "1rem", color = "#000" }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  // Function to calculate time left
  function calculateTimeLeft() {
    const now = new Date().getTime();
    const difference = targetDate - now;

    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / (1000 * 60)) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  // Update the countdown every second
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  const renderDigit = (value, label) => (
    <div className="flip-clock-item">
      <div className="flip-digit">
        <AnimatePresence mode="wait">
          <motion.span
            key={value} 
            initial={{ y:-20, opacity: 0 }} // Start with opacity 0.5
            animate={{ y: 0, opacity: 1 }} // Animate to opacity 1
            exit={{ y: 20, opacity: 0 }} // Exit with opacity 0.5
            transition={{ duration: 0.2, ease:'easeInOut', type:'tween' }}
          >
            {String(value).padStart(2, "0")}
          </motion.span>
        </AnimatePresence>
      </div>
      <span className="label">{label}</span>
    </div>
  );

  return (
    <div className="flip-clock" style={{ fontSize, color }}>
      {renderDigit(timeLeft.days, "روز")}
      {renderDigit(timeLeft.hours, "ساعت")}
      {renderDigit(timeLeft.minutes, "دقیقه")}
      {renderDigit(timeLeft.seconds, "ثانیه")}
    </div>
  );
};

export default FlipClock;
