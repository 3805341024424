import './App.css';
import './fonts.css'
import Counter from './Pages/Counter/Page/Counter';


function App() {
  return (
    <>
      <Counter />
    </>
  );
}

export default App;
