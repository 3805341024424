import React, { useEffect, useState } from 'react';
import './ImgSection.css';
import Lottie from 'lottie-react';
import LoadingDots from '../../../PublicComponent/LoadingDots';

function ImgSection() {

  const [FirstAnimationData, setFirstAnimationData] = useState(null);

  useEffect(() => {
    fetch('https://gist.githubusercontent.com/alirewzak/489a9a3f797ebfc9988c3a33f7fc3061/raw/6b1bc4fb8680bd50397dcda2e01a4151dc897d30/ComingSoon.json')
      .then((response) => response.json())
      .then((data) => setFirstAnimationData(data))
  }, [])

  return (
    <>
      <div className='ImgSection_Container'>

        {!FirstAnimationData
            ?
            <div style={{ margin: '60px' }}>
              <LoadingDots Color='var(--Primary-EZ)' />
            </div>
            :
            <>
              <Lottie
                animationData={FirstAnimationData}
                loop={true}
                autoplay
              />
            </>
        }
      </div>
    </>
  )
}

export default ImgSection