import React from 'react';
import './TextSection.css';
import GoDownArrow from '../../../publicAssets/GoToDownArrow.svg';

function TextSection() {

  const handleClick = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  }

  return (
    <>
      <div className='TextSection_Container'>
        <div> اِلیـــت زون مـسـیــری مـدرن </div>
        <div> در بازار مصالح ساختمانی ایران </div>
        {/* <img src={GoDownArrow} alt='Go Down'  onClick={handleClick}/> */}
      </div>
    </>
  )
}

export default TextSection